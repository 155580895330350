import styles from './InputCheckbox.module.scss';

type Props = {
  name: string;
  inputValue: string;
  checked?: boolean;
  onChange: (e: React.ChangeEvent) => void;
  children?: React.ReactNode;
  inputRef?: React.Ref<HTMLInputElement>;
};

function InputCheckbox({ name, inputValue, checked = false, onChange, inputRef, children }: Props) {
  return (
    <label key={inputValue} className={styles.inputCheckbox}>
      <input
        type='checkbox'
        name={name}
        ref={inputRef}
        value={inputValue}
        checked={checked}
        onChange={(e) => onChange(e)}
        className={checked ? styles.manualChecked : ''}
      />
      <span className={styles.span}>{children}</span>
    </label>
  );
}

export default InputCheckbox;
