import styles from './FormValue.module.scss';
import FormLabel from '../../form/FormLabel/FormLabel';
import formStyle from '@/styles/modules/form.module.scss';

type Props = {
  children: React.ReactNode;
  label: string;
};

function FormValue({ children, label }: Props) {
  return (
    <div className={formStyle.formComponentStyle}>
      {/* ラベル */}
      {label && <FormLabel>{label}</FormLabel>}
      {/* フィールド */}
      <div className={styles.fieldWrapper}>{children}</div>
    </div>
  );
}
export default FormValue;
