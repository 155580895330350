import { http } from '@/lib/http';
import { APIResponse } from '@/types';

export type PostSearchCodeResponse = APIResponse<{
  campaignId: number;
  caution: string;
  type: number;
  lotteryEventId: number;
  lotteryEventName: string;
  lotteryEventBranchId: number;
  lotteryEventBranchName: string;
}>;

export const postSearchCode = async (serialCode: string) => {
  return await http<PostSearchCodeResponse>('/user/v1/campaign/search-code', {
    method: 'POST',
    body: JSON.stringify({
      oem_service_id: process.env.NEXT_PUBLIC_SERVICE_ID,
      serial_code: String(serialCode).toLocaleUpperCase(),
    }),
  });
};
