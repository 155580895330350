'use client';

import ActionButton from '@/components/form/ActionButton/ActionButton';
import FormValue from '@/components/form/FormValue/FormValue';
import ModalBase from '@/components/ui/ModalBase/ModalBase';
import { swal } from '@/lib/sweetalert';
import layoutStyles from '@/styles/modules/layout.module.scss';
import utilsStyles from '@/styles/modules/utils.module.scss';
import { pickErrorMessages } from '@/utils/functions';
import { useRouter, useSearchParams } from 'next/navigation';
import { useState } from 'react';
import { postDrawLotsFromCampaign } from '../../api/postDrawLotsFromCampaign';
import { postSearchCode } from '../../api/postSearchCode';
import { useIsOpenSerialCodeModal } from '../../state';
import SerialCodeInput from '../SerialCodeInput/SerialCodeInput';
import styles from './SerialCodeModal.module.scss';

function SerialCodeModal() {
  const searchParams = useSearchParams();
  const [serialCode, setSerialCode] = useState(searchParams.get('serial-code') ?? '');
  const [campaignData, setCampaignData] = useState({
    lotteryEventName: '',
    lotteryEventBranchId: 0,
    lotteryEventBranchName: '',
    caution: '',
  });
  const [step, setStep] = useState(1);
  const router = useRouter();

  const [isOpen, setIsOpen] = useIsOpenSerialCodeModal();

  const valid = async () => {
    try {
      const { result } = await postSearchCode(serialCode);

      setCampaignData({
        lotteryEventName: result.lotteryEventName,
        lotteryEventBranchId: result.lotteryEventBranchId,
        lotteryEventBranchName: result.lotteryEventBranchName,
        caution: result.caution,
      });
      // 確認セクションへ
      setStep(2);

      // もし、くじ詳細ページにいなければくじ詳細に強制遷移
      if (location.pathname !== `/campaign-lotteries/${result.lotteryEventBranchId}`) {
        router.push(`/campaign-lotteries/${result.lotteryEventBranchId}`);
        setIsOpen(false);
      }
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) });
    }
  };

  const draw = async () => {
    try {
      const { result } = await postDrawLotsFromCampaign({
        lotteryEventBranchId: campaignData.lotteryEventBranchId,
        serialCode: serialCode,
      });
      await goAnimation(result.lotteryDrawHistoryId);

      // 初期化して閉じる
      reset();
      setIsOpen(false);
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) });
    }
  };

  // アニメーション
  const goAnimation = (lotteryDrawHistoryId: number) => {
    return router.push(`/draw/${lotteryDrawHistoryId}/anime`);
  };

  const reset = () => {
    setSerialCode('');
    setStep(1);
  };

  const modalContent = () => {
    switch (step) {
      case 1:
        return inputCode({
          initialValue: serialCode,
          onChange: (value) => setSerialCode(value),
          valid: valid,
        });
      case 2:
        return confirm(serialCode, campaignData, draw);
      default:
        return <></>;
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div className={layoutStyles.modalPadding}>{modalContent()}</div>
    </ModalBase>
  );
}

function inputCode({
  initialValue = '',
  onChange,
  valid,
}: {
  initialValue?: string;
  onChange: (value: string) => void;
  valid: () => void;
}) {
  return (
    <>
      {/* 入力 */}
      <div className={layoutStyles.modalTitle}>シリアルコードを入力してください</div>
      <div className={utilsStyles.mtSmall}>
        <SerialCodeInput initialValue={initialValue} onChange={(value) => onChange(value)} />
      </div>
      <div className={utilsStyles.mtSmall}>
        <ActionButton color='primary' onClick={valid} size='fullWidth'>
          コードを適用する
        </ActionButton>
      </div>
    </>
  );
}

function confirm(
  serialCode: string,
  campaignData: {
    lotteryEventName: string; // 親イベント名
    lotteryEventBranchId: number;
    lotteryEventBranchName: string;
    caution: string;
  },
  draw: () => void,
) {
  return (
    <>
      {/* 確認 */}
      <div className={layoutStyles.modalTitle}>シリアルコードを使用してくじを引きます</div>
      <div className={utilsStyles.mtSmall}>
        <FormValue label={''}>
          <div className={`${utilsStyles.textSmall} ${utilsStyles.centered} ${utilsStyles.spaceBetween}`}>
            <span>適用されたコード</span>
            <p className={`${utilsStyles.textSmall} ${utilsStyles.textBold} ${styles.textSerialCode}`}>
              <em>{serialCode}</em>
            </p>
          </div>
        </FormValue>

        <hr className={utilsStyles.pinkishgrayBgColor} />
        <div className={`${utilsStyles.mtSmall} ${utilsStyles.textCenter}`}>
          <p className={`${utilsStyles.textSmall} ${utilsStyles.textBold}`}>対象くじイベント</p>
        </div>
        <hr className={`${utilsStyles.pinkishgrayBgColor} ${utilsStyles.mtXSmall}`} />
        <div
          className={`${utilsStyles.mtSmall} ${utilsStyles.textCenter} ${utilsStyles.textSmall} ${utilsStyles.primaryColor} ${utilsStyles.textBold}`}
        >
          {campaignData.lotteryEventBranchName}
        </div>

        <hr className={`${utilsStyles.pinkishgrayBgColor} ${utilsStyles.mtXSmall}`} />
        <div className={`${utilsStyles.mtSmall} ${styles.itemCaution}`}>
          <p className={`${utilsStyles.textSmall} ${utilsStyles.textPrewrap}`}>{campaignData.caution}</p>
        </div>
        <div className={utilsStyles.mtSmall}>
          <ActionButton size='fullWidth' color='primary' onClick={draw}>
            くじを引く
          </ActionButton>
        </div>
      </div>
    </>
  );
}

export default SerialCodeModal;
