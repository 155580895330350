import { http } from '@/lib/http';
import { APIResponse } from '@/types';

export type PostDrawCampaignParams = {
  lotteryEventBranchId: number;
  serialCode: string;
};

export type PostDrawCampaignResponse = APIResponse<{
  lotteryDrawHistoryId: number;
}>;

export const postDrawLotsFromCampaign = async (params: PostDrawCampaignParams) => {
  return await http<PostDrawCampaignResponse>('/user/v1/lottery-draw-lots-from-campaign', {
    method: 'POST',
    body: JSON.stringify({
      lottery_event_branch_id: params.lotteryEventBranchId,
      serial_code: String(params.serialCode).toLocaleUpperCase(),
    }),
  });
};
