import { http } from '@/lib/http';
import { APIResponse } from '@/types';

export type PostNewsLetterParams = {
  permission: number;
};

export type PostNewsLetterResponse = APIResponse<{
  permission: number;
}>;

export const postNewsLetter = async (params: PostNewsLetterParams) => {
  return await http<PostNewsLetterResponse>('user/v1/newsletter', {
    method: 'POST',
    body: JSON.stringify({
      permission: params.permission,
    }),
  });
};
