import styles from './FormLabel.module.scss';

type Props = {
  children: React.ReactNode;
};

function FormLabel({ children }: Props) {
  return (
    <div className={styles.componentStyle}>
      <label>{children}</label>
    </div>
  );
}
export default FormLabel;
