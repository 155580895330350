export const getCookieValue = (key: string) => {
  if (!document) return '';
  const cookies = document.cookie.split(';');
  const foundCookie = cookies.find((cookie) => cookie.split('=')[0].trim() === key.trim());
  if (foundCookie) {
    return decodeURIComponent(foundCookie.split('=')[1]);
  }
  return '';
};

export const setCookie = (key: string, value: string, maxAge?: number) => {
  if (!document) return;
  let setValue = `${key}=${value}`;
  if (maxAge && maxAge > 0) setValue += `;max-age=${maxAge}`;
  document.cookie = `${setValue};samesite=Lax;path=/;secure`;
};

export const removeCookie = (key: string) => {
  if (!document) return;
  document.cookie = `${key}=;max-age=0;samesite=Lax;path=/;secure`;
};
