'use client';

import { useForm } from 'react-hook-form';
import SerialCodeFormText from '../SerialCodeFormText/SerialCodeFormText';
import styles from './SerialCodeInput.module.scss';

function SerialCodeInput({
  initialValue = '',
  onChange,
}: {
  initialValue: string;
  onChange: (value: string) => void;
}) {
  const { getValues, control, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      serialCode: initialValue ?? '',
    },
  });

  const updateValue = () => {
    onChange(getValues('serialCode'));
  };

  return (
    <div className={styles.serialCodeInput}>
      <SerialCodeFormText name='serialCode' control={control} onChange={() => updateValue()} />
    </div>
  );
}

export default SerialCodeInput;
